import { useState, useCallback, useEffect } from 'react';
import { getCookieKey, deleteAllCookies } from '../../../LocalStorage/CookiesStorage'; 
import environment from '../../../Environments/Environment';
import { useNavigate } from 'react-router-dom';

const useExperienceEditing = (experience_id , error_redirect = '/login') => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [statusCode, setStatusCode] = useState(null);
    const navigate = useNavigate();

    const fetchExperience = useCallback(async () => {
        setLoading(true);
        try {
            const token = 'Token ' + getCookieKey('token');
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', Authorization: token },
            };
            const response = await fetch(
                `${environment.server_root}/api/avatar/get_experience_editing?experience_id=${experience_id}`,
                requestOptions
            );
            const result = await response.json();
            if (!response.ok) {
                setError(result?.error)
                navigate('/profile')
            }
            result.status_code = response.status;
            setStatusCode(response.status);
            setData(result?.experience);
        } catch (error) {
            console.log(error)
            setData(null)
            return;
        } finally {
            setLoading(false);
        }
    }, [experience_id]);

    // Initial fetch
    useEffect(() => {
        fetchExperience();
    }, [fetchExperience]);

    return { data, loading, error, refetch: fetchExperience };
};

export default useExperienceEditing;