import React from 'react';
import { Outlet,  } from 'react-router-dom';
import './VirtualTours'
const VirtualTours = () => {

    return (
        <div className='w3-col'>
            <Outlet />
        </div>
    )
}
export default VirtualTours;