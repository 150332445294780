import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { getCookieKey, deleteAllCookies, set } from '../../LocalStorage/CookiesStorage'
import './NavigationBar.css'
import * as Media from '../../Media/Media'
import { useUpdateProfileVisits } from '../../Servicies/CustomHook'
const Navigationbar = () => {
    const navigate = useNavigate()

    const { updateProfileVisits } = useUpdateProfileVisits();

    const [showNavbar, setShowNavbar] = useState(false)
    const [token, setToken] = useState(getCookieKey('token'))
    const handleShowNavbar = () => {
        setShowNavbar(!showNavbar)
    }
    function Logout() {
        updateProfileVisits({ "end_session": true }).then(result => {
            deleteAllCookies()
            setToken(null)
            navigate('/')
        })

    }
    return (
        <header className="w3-bar dark-bg-1 w3-padding-small">
            <nav className="navbar">
                <div className="container-nav">
                    <div className="logo-gret center-item-row" style={{ gap: 10, cursor: 'pointer' }}>
                        <img alt='BuildVR Gretxp' loading="lazy" src={Media.GretIcon4} onClick={() => navigate('/')} className='img-nav' />
                    </div>
                    <div className="menu-icon" onClick={handleShowNavbar}>
                        <i className="fa fa-bars" style={{ fontSize: 25, color: 'white' }} ></i>
                    </div>
                    <div className={`nav-elements  ${showNavbar && 'active'}`}>
                        <ul>
                            <li className='w3-hide-large w3-hide-medium'>
                                <p className='effect-underline' onClick={handleShowNavbar}>
                                    <i className="fa fa-close" style={{ fontSize: 25, color: 'white' }} ></i>
                                </p>
                            </li>
                            <li >
                                <Link className='effect-underline' to="/"> Home </Link>
                            </li>
                            <li>
                                <Link className='effect-underline' to="/create">Create VRE</Link>
                            </li>
                            {getCookieKey('token') &&
                                <li>
                                    <Link className='effect-underline' to="/profile">Profile</Link >
                                </li>
                            }
                            <li>
                                <Link className='effect-underline' to="/pricing">Pricing</Link>
                            </li>
                            <li>
                                <Link className='effect-underline' to="/vr-video">VR Video</Link>
                            </li>
                            <li>
                                <Link className='effect-underline' to="/blog">Blog</Link>
                            </li>
                            <li>
                                <Link className='effect-underline' to="/contact-us">Contact</Link>
                            </li>
                            <li className="w3-hide-large w3-hide-medium">
                                {getCookieKey('token') ?
                                    <Link className="gret-blue-button" style={{ padding: '8px 24px' }} to="/logout">Logout</Link >
                                    :
                                    <Link className="gret-blue-button" style={{ padding: '8px 24px' }} to="/login">
                                        Login
                                    </Link>
                                }
                            </li>
                        </ul>
                    </div>
                    <div className={`nav-elements w3-hide-small  ${showNavbar && 'active'}`}>
                        {getCookieKey('token') ?
                            <Link className="gret-blue-button" style={{ padding: '8px 24px' }} to="/logout">Logout</Link >
                            :
                            <Link className="gret-blue-button" style={{ padding: '8px 24px' }} to="/login">
                                Login
                            </Link>
                        }
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default Navigationbar
