import * as THREE from "three";

var media1 = "https://storage.googleapis.com/grubox-store/gret/local/avatar/doggy/29809494-marble-color-texture-background-abstract-pattern-stone-tiles-design-marbl.webp";
var media2 = "https://storage.googleapis.com/grubox-store/gret/local/avatar/doggy/Black-and-gold-marble-tile.webp";
var media3 = "https://storage.googleapis.com/grubox-store/gret/local/avatar/doggy/download.webp";

export function ApplyTexture(mesh, file, repeat = { x: 1, y: 1 }) {
  var loader = new THREE.TextureLoader();
  loader.load(file, function (texture) {
    texture.repeat.set(repeat.x || 1, repeat.y || 1);
    texture.wrapS = THREE.RepeatWrapping;
    texture.wrapT = THREE.RepeatWrapping;
    texture.needsUpdate = true;
    texture.flipY = true;
    texture.encoding = THREE.SRGBColorSpace;
    texture.anisotropy = 16;
    texture.mapping = THREE.ACESFilmicToneMapping;
    if (mesh && mesh.material && mesh.material.map) {
      mesh.material.map.dispose();
      mesh.material.map = texture;
      mesh.material.map.needsUpdate = true;
      mesh.material.needsUpdate = true;
    }
    console.log(mesh,file)
  });
}

export function applyBgTextureWithLink(scene, renderer, link) {
  const loader = new THREE.TextureLoader();
  var textureLink;
  if (link === "" || link === null || link === undefined) {
    // textureLink ="https://storage.googleapis.com/grubox-store/gret/avatar/GretXP1_room.001/landscape.webp"
    scene.background = new THREE.Color("black");

  }
  else {
    textureLink = link;
    loader.load(textureLink, function (texture) {
      texture.wrapS = THREE.RepeatWrapping;
      texture.wrapT = THREE.RepeatWrapping;
      texture.needsUpdate = true;
      texture.flipY = true;
      texture.encoding = THREE.SRGBColorSpace;
      texture.anisotropy = renderer.capabilities.getMaxAnisotropy();
      texture.mapping = THREE.ACESFilmicToneMapping;
      scene.background = texture;
    });
  }

}

export function applySphereTextureWithLink(scene, renderer, link) {
  const loader = new THREE.TextureLoader();
  var textureLink;
  if (link === "" || link === null || link === undefined) {
    var geometry = new THREE.SphereGeometry(15, 256, 256);;
    var material = new THREE.MeshStandardMaterial({
      side: THREE.BackSide,
      displacementScale: - 4.0
    });
    var mesh = new THREE.Mesh(geometry, material);
    mesh.material.color = 0xDCDCDC;
    // var geometry = new THREE.CylinderGeometry(300, 300, 600, 100, true);
    // var material = [
    //   new THREE.MeshBasicMaterial({ color: 0xDCDCDC }),
    //   new THREE.MeshBasicMaterial({ color: 0x0000ff }),
    //   new THREE.MeshBasicMaterial({ color: 0xff0000 })
    // ];
    // var mesh = new THREE.Mesh(geometry, material);
    // scene.add(mesh);
  }
  else {
    textureLink = link;
    loader.load(textureLink, function (texture) {
      texture.wrapS = THREE.RepeatWrapping;
      texture.wrapT = THREE.RepeatWrapping;
      texture.needsUpdate = true;
      texture.flipY = true;
      texture.flipX = true;
      // texture.rotation = Math.PI*2;
      texture.encoding = THREE.SRGBColorSpace;
      texture.anisotropy = renderer.capabilities.getMaxAnisotropy();
      texture.mapping = THREE.ACESFilmicToneMapping;
      texture.colorSpace = THREE.SRGBColorSpace;
      texture.minFilter = THREE.NearestFilter;
      texture.generateMipmaps = false;
      var geometry = new THREE.SphereGeometry(15, 256, 256);;
      var material = new THREE.MeshStandardMaterial({
        side: THREE.BackSide,
        displacementScale: - 4.0
      });
      // var geometry = new THREE.CylinderGeometry(300, 300, 600, 100, true);
      // var material = [
      //   new THREE.MeshBasicMaterial({ map: texture, fog: false, color: 0xFFFFFF, side: THREE.DoubleSide }),
      //   new THREE.MeshBasicMaterial({ color: 0x0000ff }),
      //   new THREE.MeshBasicMaterial({ color: 0xff0000 })
      // ];
      // var material = new THREE.MeshBasicMaterial( { map: texture,fog:false,color:0xFFFFFF,side: THREE.DoubleSide} );    
      var mesh = new THREE.Mesh(geometry, material);
      mesh.material.map = texture;
      mesh['background_sphere'] = true
      scene.add(mesh);
    });
  }

}

