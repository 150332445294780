import { useState, useEffect } from 'react';
import { getCookieKey, setCookieKey } from '../../../LocalStorage/CookiesStorage';
import environment from '../../../Environments/Environment'

const useUserSubscription = () => {
  const [subscription, setSubscription] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCategories = async () => {
      setLoading(true);
      const token = 'Token ' + getCookieKey('token');
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', Authorization: token },
      };

      let url = `${environment.server_root}/api/profile/get_profile_info/`;

      try {
        const response = await fetch(url, requestOptions);
        const data = await response.json();

        if (response.ok) {
          var subscription_details = data.profile.subscription_details
          var user_data = getCookieKey('user_data') || {}
          if (subscription_details) {
            if (new Date(subscription_details?.subscription_end_date) < new Date()) {
              user_data['is_subscriber'] = false;
            } else {
              user_data['is_subscriber'] = true;
            }
            user_data['plan_start_date'] = subscription_details?.subscription_start_date;
            user_data['subscription_stop_date'] = subscription_details?.subscription_end_date;
          } else {
            user_data['is_subscriber'] = false;
          }
          setCookieKey("user_data", user_data)
          setSubscription(subscription_details)
        } else {
          throw new Error(data.detail || 'Error fetching categories');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    if (getCookieKey('token')) {
      fetchCategories();
    }

  }, []);

  return { subscription, loading, error };
};

export default useUserSubscription;
