import React, { useState, useEffect } from 'react'
import * as colors from '../../Styles/Colors';
import Modal from 'react-modal';

const ErrorModal = (props) => {
    return (
        <div>
            {
                props.visible &&
                <Modal
                    isOpen={props.visible}
                    onRequestClose={props.closeHandle}
                    className='error_modal'
                    contentLabel="Example Modal">
                    <div style={{ display: 'grid', gridTemplateColumns: '90% 10%', marginLeft: '5%' }}>
                        <div className="w3-row w3-half" style={{ color: colors.dark_blue_2 }}>
                            <h2 style={{ fontWeight: 'bold', paddingLeft: '4%' }}>Error!</h2>
                        </div>
                        <button className="link_button" onClick={props.closeHandle}><i className="fa fa-close" style={{ fontSize: '24px' }}></i></button>
                    </div>
                    <div>
                        <h2 style={{ marginLeft: '5%' }}>{props.errorMessage}</h2>
                    </div>
                </Modal>
            }
        </div>
    )
}

export default React.memo(ErrorModal)
