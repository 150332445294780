export const LandingPageTags = {
    title: "BuildVr.Gretxp | Free AI Platform for 3D Experiences",
    preview: 'https://storage.googleapis.com/grubox-store/gret/avatar/Screenshot 2023-08-03 170058/Screenshot_2023-08-03_170058.png',
    href: `https://buildvr.gretxp.com/`,
    description: 'Explore BuildVr.Gretxp, the AI-powered free platform for 3D rendering, virtual showrooms, and AI website building. Transform your virtual reality projects today!',
}

export const LoginPageTags = {
    title: "Login to BuildVr.Gretxp | Explore Cutting-Edge 3D Experiences",
    preview: "https://storage.googleapis.com/grubox-store/gret/avatar/Screenshot 2023-08-03 174506/Screenshot_2023-08-03_174506.png",
    href: "https://buildvr.gretxp.com/login",
    description: 'Enter a new dimension of creativity with BuildVr.Gretxp. Login to access state-of-the-art WebVR integration, powerful 3D visualization tools, and user-friendly design features perfect for architects, designers, and VR enthusiasts alike.',
}

export const CreateVREPageTags = {
    title: 'Create 3D VR Experiences | BuildVr.Gretxp Editor',
    preview: "https://storage.googleapis.com/grubox-store/gret/avatar/Screenshot 2023-08-03 172106/Screenshot_2023-08-03_172106.png",
    href: 'https://buildvr.gretxp.com/create/',
    description: 'Utilize our advanced editor at BuildVr.Gretxp to craft stunning VR experiences. Easily upload models, select free 3D assets, and create interactive web pages and VR environments in a few clicks.',
}

export const CreateVRWPageTags = {
    title: 'Design 3D Websites Easily | BuildVr.Gretxp Editor',
    href: 'https://buildvr.gretxp.com/create-vrwebsite/',
    preview: "https://storage.googleapis.com/grubox-store/gret/avatar/Screenshot 2023-08-03 172106/Screenshot_2023-08-03_172106.png",
    description: 'Create immersive 3D websites using BuildVr.Gretxp’s editor. From scrolling single-page sites to interactive presentations, add social media integrations, signup popups, and more to engage your audience uniquely.',
}

export const PublishVREPageTags = {
    title: 'Share Your VR Experience | BuildVr.Gretxp',
    preview: '',
    href: 'https://buildvr.gretxp.com/virtual-showroom/',
    description: 'Publish and share your VR creations with the world using BuildVr.Gretxp. Enhance your brand’s marketing with unique VR experiences that captivate and engage audiences online.'
}

export const PublishVRWPageTags = {
    title: 'Launch Your 3D Website | BuildVr.Gretxp',
    preview: '',
    href: 'https://buildvr.gretxp.com/3dwebsite/',
    description: 'Showcase your VR and 3D experiences through a comprehensive website on BuildVr.Gretxp. Reach a broader audience and deliver stunning visual stories with ease.',
}

export const StoriesPageTags = {
    title: "Discover 3D Stories | BuildVr.Gretxp",
    preview: "https://storage.googleapis.com/grubox-store/gret/avatar/Screenshot 2023-08-01 144141/Screenshot_2023-08-01_144141.png",
    href: "https://buildvr.gretxp.com/3d-visualization",
    description: 'Explore a world of 3D stories and virtual experiences on BuildVr.Gretxp. Start from ready-made templates and craft your VR scenes today. Ideal for innovators in 3D website creation.'
}

export const visualization3DPageTags = {
    title: "Discover 3D Visualization | BuildVr.Gretxp",
    preview: "https://storage.googleapis.com/grubox-store/gret/avatar/Screenshot 2023-08-01 144141/Screenshot_2023-08-01_144141.png",
    href: "https://buildvr.gretxp.com/3d-visualization",
    description: 'Showcase your physical products like never before with Buildvr 3D visualization platform. This page showcases templates, just upload 3D models and present your ideas, prototypes, and products in stunning VR settings. Buildvr offers an exciting opportunity to engage your audience with immersive VR experiences. Start using today, No technical skills required.'
}

export const SocialMediaPostsPageTags = {
    title: "Discover 3D Social Media Posts | BuildVr.Gretxp",
    preview: "https://storage.googleapis.com/grubox-store/gret/avatar/Screenshot 2023-08-01 144141/Screenshot_2023-08-01_144141.png",
    href: "https://buildvr.gretxp.com/social-postss",
    description: 'Craft stunning cyberpunk, metaverse, and sci-fi scenes with our intuitive design platform. Start with a template, infuse it with your own futuristic ideas, and effortlessly publish your creations. Record as videos or images, or share directly on social media platforms like Instagram and Facebook. Dive into creating your own futuristic world today and captivate your audience!'
}

export const Interior3DPageTags = {
    title: "Discover Virtual Tours | BuildVr.Gretxp",
    preview: "https://storage.googleapis.com/grubox-store/gret/avatar/Screenshot 2023-08-01 144141/Screenshot_2023-08-01_144141.png",
    href: "https://buildvr.gretxp.com/virtual-tours",
    description: 'Explore our collection of templates for creating photorealistic virtual tours. Perfect for museums, hotels, and real estate, these templates let you craft immersive experiences that captivate and attract visitors. Start creating amazing virtual tours today and bring your venues to life like never before!'
}

export const VirtualShowroomPageTags = {
    title: "Discover 3D Virtual Showroom | BuildVr.Gretxp",
    preview: "https://storage.googleapis.com/grubox-store/gret/avatar/Screenshot 2023-08-01 144141/Screenshot_2023-08-01_144141.png",
    href: "https://buildvr.gretxp.com/virtual-showrooms",
    description: 'Discover the future of branding with our immersive virtual showrooms and customizable virtual event stages. Elevate your brand and captivate your audience with our easy-to-use templates—no coding or prior VR knowledge needed. Start engaging like never before; pick and publish your virtual space in minutes!'
}

export const VRToursPageTags = {
    title: "Explore Digital & Virtual Tours | BuildVr.Gretxp",
    preview: "https://storage.googleapis.com/grubox-store/gret/avatar/Screenshot 2023-08-01 144141/Screenshot_2023-08-01_144141.png",
    href: "https://buildvr.gretxp.com/virtual-showrooms",
    description: 'Experience the future with our cutting-edge digital tours, virtual tours, and factory tours. Dive into immersive 3D and VR environments with BuildVr.Gretxp. Easily create and customize your virtual spaces with no coding needed. Start engaging your audience in new and exciting ways today!'
}

export const VRShowroomEditorTags = {
    title: "Create Your Virtual Showroom | BuildVr.Gretxp Editor",
    preview: "https://storage.googleapis.com/grubox-store/gret/avatar/Screenshot_2023-08-01_144141/Editor_Preview_Image.png",
    href: "https://buildvr.gretxp.com/vrshowroom-editor",
    description: 'Unleash your creativity with the BuildVr.Gretxp Editor! Easily design and personalize virtual showrooms by adding 3D models, images, and videos. Customize layouts, themes, and interactions—no coding needed. Create immersive, interactive virtual spaces to captivate your audience like never before.'
}

export const WebsiteBuilderTags = {
    title: "Home - AI Website Builder | BuildVr.Gretxp",
    preview: "https://storage.googleapis.com/grubox-store/gret/avatar/Screenshot 2023-08-01 144141/Screenshot_2023-08-01_144141.png",
    href: "https://buildvr.gretxp.com/website-builder",
    description: 'Welcome to BuildVr.Gretxp! Dive into our AI-powered website builder and explore our vast collection of VR experiences. Start creating with our intuitive tools and templates designed for designers and digital creators. Transform your virtual reality training and presentations with our platform.'
}

export const HomePageTags = {
    title: "Home - Your Gateway to 3D Creativity | BuildVr.Gretxp",
    preview: "https://storage.googleapis.com/grubox-store/gret/avatar/Screenshot 2023-08-01 144141/Screenshot_2023-08-01_144141.png",
    href: "https://buildvr.gretxp.com/website-builder",
    description: 'Welcome to the home of innovation at BuildVr.Gretxp! Explore our vast collection of VR experiences and start creating with our intuitive tools and templates. Ideal for designers and digital creators.'
}


export const BlogsPageTags = {
    title: "Latest in VR and 3D Websites | BuildVr.Gretxp Blogs",    
    preview: "https://storage.googleapis.com/grubox-store/gret/avatar/Screenshot 2023-08-01 144141/Screenshot_2023-08-01_144141.png",
    href: "https://buildvr.gretxp.com/blog",
    description: 'Stay updated with the latest in VR technology and 3D web design with BuildVr.Gretxp’s blog. Learn marketing strategies, touring tips, and training insights to maximize your digital presence.'
}

export const DashboardPageTags = {
    title: "Dashboard - Manage Your VR Projects | BuildVr.Gretxp",    
    preview: "https://storage.googleapis.com/grubox-store/gret/avatar/Screenshot 2023-08-01 144141/Screenshot_2023-08-01_144141.png",
    href: "https://buildvr.gretxp.com/dashboard",
    description: 'Control and manage your VR projects efficiently with BuildVr.Gretxp’s dashboard. Get the latest insights on VR technology, software updates, and expert tips for successful digital marketing.'
}

export const VRVideoPageTags = {
    title: "VR Videos - Immersive Viewing Experience | BuildVr.Gretxp",    
    preview: "https://storage.googleapis.com/grubox-store/gret/avatar/Screenshot 2023-08-01 144141/Screenshot_2023-08-01_144141.png",
    href: "https://buildvr.gretxp.com/vr-video/kevin46",
    description: 'Step into the future of video with BuildVr.Gretxp’s VR video platform. Upload and share your videos in VR format, offering an unmatched immersive experience to viewers worldwide.'
}

export const VRVideoLandingPageTags = {
    title: "Video to VR Videos Convertor | BuildVr.Gretxp",    
    preview: "https://storage.googleapis.com/grubox-store/gret/avatar/Screenshot 2023-08-01 144141/Screenshot_2023-08-01_144141.png",
    href: "https://buildvr.gretxp.com/vr-video/",
    description: 'Transform your videos into immersive VR experiences in seconds with our user-friendly platform. Simply upload your video, and publish it as a VR experience. Get a shareable link immediately to engage your audience in a whole new dimension. No technical skills required—start captivating viewers today- no coding or prior VR knowledge needed!'
}

export const VirtualTourLandingPageTags = {
    title: "3D Model to Virtual Tour Converter | BuildVR.Gretxp",    
    preview: "https://storage.googleapis.com/grubox-store/gret/avatar/Screenshot 2023-08-01 144141/Screenshot_2023-08-01_144141.png",
    href: "https://buildvr.gretxp.com/virtual-tour/",
    description: 'Transform your 3D models into interactive virtual tours effortlessly with our user-friendly platform. Simply upload your 3D model, and publish it as an immersive tour. Get a shareable link instantly to captivate your audience and showcase your space like never before—no coding or prior technical knowledge required!'
}


export const VRVideoPublishPageTags = {
    title: "VR Videos Experience | BuildVr.Gretxp ",    
    preview: "https://storage.googleapis.com/grubox-store/gret/avatar/Screenshot 2023-08-01 144141/Screenshot_2023-08-01_144141.png",
    href: "https://buildvr.gretxp.com/vr-video/",
    description: 'This is a virtual reality experience with Title "CCC" effortlessly created with Buildvr VRE editor. Buildvr allows you to Drag and drop exciting 3D models or use our quick editor to bring your immersive visions to life. No complex skills needed—just design, publish, and mesmerize your audience with engaging VR content.'
}

export const LogoutPageTags = {
    title: "Thank You for Visiting BuildVr.Gretxp!",    
    preview: null,
    href: "https://buildvr.gretxp.com/logout",
    description: 'Logged out of BuildVr.Gretxp? We hope to see you back soon! Continue to explore groundbreaking 3D and VR tools that bring your digital media to life. Bookmark us for easy access!'
}

export const PricingPageTags = {
    title: "BuildVr.Gretxp || Pricing - Choose Your Plan",
    preview: null,
    href: "https://buildvr.gretxp.com/pricing",
    description: "Explore our flexible pricing plans designed to fit your needs. Get started with Buildvr Gretxp today and unlock powerful features for your VR website development projects."
}

export const ContactUsPageTags = {
    title: "BuildVr.Gretxp || Get in Touch - Contact Us Today",
    preview: null,
    href: "https://buildvr.gretxp.com/contact-us",
    description: `Connect with us at BuildVr.Gretxp to explore our AI-powered, no-code platform for innovative VR website development. Whether you have questions, feedback, or want to learn more about our services, our team is here to assist you. Thank you for visiting BuildVr.Gretxp - let's build something amazing together!`,
}

export const VRWContactUsPageTags = {
    title: "BuildVr.Gretxp || Get in Touch - ",
    preview: null,
    href: "https://buildvr.gretxp.com/site/",
    description: `Reach out to BuildVr.Gretxp for any inquiries, assistance, or collaborations. Our team of experts is here to help you explore our AI-driven, no-code platform for innovative VR website development.`,
}

