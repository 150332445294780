import React, { Suspense, lazy, useEffect, useState } from 'react';
import importRetry from '../../importRetry';
import * as colors from '../../Styles/Colors';
import { ConvertVideoToExp, UploadModels, get_experiences_templates } from '../../Servicies/ModelService';
import { getCookieKey } from '../../LocalStorage/CookiesStorage';
import { Link, Outlet, useNavigate } from 'react-router-dom';

import Navigationbar from '../Landing/Navigationbar';
import { BsHeadsetVr } from 'react-icons/bs';
import { dark_blue_2 } from '../../Styles/Colors';
import { getFileType } from '../../SceneComponents/SceneComponents';
import { update_profile_visits } from '../../Servicies/SessionService';
import ErrorModal from '../PopupComponents/ErrorModal';
import Modal from 'react-modal';
import { VirtualTourLandingPageTags } from '../Seo/SeoPageTags';
import SeoTags from '../Seo/SeoTags';
import { useUpdateProfileVisits } from '../../Servicies/CustomHook';
import { ImCross } from 'react-icons/im';
import { save_experience } from '../../Servicies/ExperienceService';

const StatusCodeHandling = lazy(() => importRetry(() => import('../PopupComponents/StatusCodeHandling')));
const ThreeDotsLoader = lazy(() => importRetry(() => import('../LoaderPages/ThreeDotsLoader')));
const LoginModal = lazy(() => importRetry(() => import("../PopupComponents/LoginModal")));

const VRTHomePage = () => {
    const [statusCodeHandling, setStatusCodeHandling] = useState(false)
    const [statusCode, setStatusCode] = useState(200)

    const { updateProfileVisits } = useUpdateProfileVisits();

    const [loadingMessage, setLoadingMessage] = useState('');
    const [loading, setLoading] = useState(false)

    const [login, setLogin] = useState(false);
    const [loginPopup, setLoginPopup] = useState(false)

    const [superUser, setSuperUser] = useState(false)
    const [videoUpload, setVideoUpload] = useState({});
    const formData = new FormData();
    const [toggleModal, setToggleModal] = useState(false)
    const [videoData, setVideoData] = useState({
        'seo': 'Create immersive virtual tours to captivate your audience. Boost engagement, showcase your spaces interactively, and redefine how your audience explores your content.',
        'title': `My-VirtualTour`,
        'is_adult_content': false
    });

    const navigate = useNavigate()

    useEffect(() => {
        if (getCookieKey('token') !== null) { setLogin(true) }
    }, [])

    useEffect(() => {
        if (getCookieKey("is_superuser")) { setSuperUser(true) }
        if (getCookieKey('token') !== null) { setLogin(true) }
    }, [getCookieKey('token')])

    const uploadVideo = (e) => {
        var file = e.target.files[0];

        // Check if the file is not a video
        if (getFileType(file) !== "model") {
            setLoadingMessage("Please select a .glb file only.");
            return; // Exit the function if the condition is not met
        }

        // Check if the file size is larger than 100MB
        if (file.size / (1024 ** 2) > 100) {
            setLoadingMessage("Please select a file less than 100MB.");
            return; // Exit the function if the condition is not met
        }

        // If both conditions are satisfied, update the state
        setVideoUpload({
            videoPreview: URL.createObjectURL(file),
            videoAsFile: file,
        });
        setLoadingMessage(""); // Clear any existing error messages
    };

    useEffect(() => {
        if (videoUpload) {
            if (videoUpload.videoAsFile) {
                setImageAction()
            }
        }
    }, [videoUpload])

    const setImageAction = async (event) => {
        formData.append("glb_file", videoUpload.videoAsFile)
        formData.append("name", videoUpload.videoAsFile.name.split('.').slice(0, -1).join('.'))
        formData.append("category_id", 1)
        formData.append("premium", false)
        setLoading(true)
        setLoadingMessage('Uploading model...')
        UploadModels(formData).then(result => {
            if (result.success === true) {
                updateProfileVisits({ "media_uploaded": true })
                setLoadingMessage('Converting model to Virtual Tour...')
                var scene_info =
                {
                    "environment_id": result.id, "avatar_id": null, "video_id": null, "video_glb_file": null, 
                    "category":"VRT","bg_image_id": null, "avatar_details": [],
                    "environment_details": {
                        "position": null, "size": null, "color": null,
                        "default_camera": {
                            "camera_zoom": 1,
                            "camera_position": { "x": 0, "y": 0, "z": 0 },
                            "camera_rotation": { "_x": -1.570, "_y": 1.165, "_z": 3.129, "_order": "XYZ", "isEuler": true },
                            "controls_target": { "x": 0, "y": 0, "z": 0 }
                        },
                        "auto_rotate": false, open_side_panel: false
                    },
                    "prop_details": [], "video_details": { "location": null, "size": null, "rotation": { '_x': 0, '_y': 0, '_z': 0 } },
                    "experience_name": videoData.title, "caption": null, "caption_details": {}, "experience_ids": [], "avatar": [], "light_info": [], "description": videoData.seo, "mesh_properties": [], "texture_details": [],
                    "audio_id": null, "audio_properties": {}, saved: false, is_template: false, is_featured: false
                }
                save_experience(scene_info).then(result => {
                    if (result.success) {
                        setLoadingMessage('Success')
                        navigate('/virtual-tour/' + result.experience_id);
                        setToggleModal(false)
                        setLoadingMessage('')
                        setLoading(false)
                    }
                })
                setVideoUpload(null)
            }
            else {
                if (result.error) {
                    setLoadingMessage(result.error)
                    setLoading(false)
                }
            }
        }).catch(error => {
            console.log(error)
            setLoadingMessage(error.response?.data?.error)
            if (error.response?.data?.error === "You have used max limit of subscription") {
                navigate('/pricing')
            }
            setLoading(false)
        })
    };
    const handleInputChange = (e) => {
        const name = e.target.name
        const value = e.target.value

        if (name === 'is_adult_content') {
            setVideoData({
                ...videoData,
                [name]: e.target.checked,
            })
        }
        else {
            setVideoData({
                ...videoData,
                [name]: value,
            })
        }
    }

    return (
        <div className='gray-bg' style={{ minHeight: '100vh' }}>
            <SeoTags
                title={VirtualTourLandingPageTags.title}
                preview={VirtualTourLandingPageTags.preview}
                href={VirtualTourLandingPageTags.href}
                description={VirtualTourLandingPageTags.description}
            />
            <Navigationbar />
            <div className='w3-row'>
                <div className='w3-quarter w3-padding'>
                    <div className='dark-card'>
                        <h1 style={{ borderRadius: '4px 4px 0px 0px' }} className='border-down-1 w3-xlarge no-margin w3-padding-small dark-bg-1 w3-center'>VR Expereinces</h1>
                        <div className='center-item w3-padding'>
                            <video autoPlay muted={true} webkit-playsinline="true" crossOrigin="anonymous" className='w3-col upload-container w3-section '>
                                <source src={'https://storage.googleapis.com/grubox-store/gret/avatar/VR%20Experience%20Intro/VR_Experience_Intro.webm'} type='video/mp4' />
                            </video>
                            <p className='w3-center'>Utilize our advanced editor at BuildVR to craft stunning VR experiences. Easily upload models, select free 3D assets, and create interactive web pages and VR environments in a few clicks.</p>
                            <div className='w3-row w3-section w3-padding'>
                                <Link to='/create' className="center-item gret-black-button">Create VR Experience</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='w3-half w3-padding'>
                    <h2 className='center-item-row w3-section' style={{ gap: 10 }}><BsHeadsetVr /><b>Virtual Tour Creator</b></h2>
                    <div className='center-item upload-container' >
                        <h3>Transform 3D models into virtual tours in just seconds.</h3>
                        {login ?
                            (toggleModal ? <>
                                <Modal
                                    isOpen={true}
                                    // onRequestClose={closePropDetailsModal}
                                    className='showFormModal2 w3-padding-large'
                                    contentLabel="Example Modal">
                                    <>
                                        <div className='space-item'>
                                            <h3 className='no-margin'>Upload 3D Model (only .glb format)</h3>
                                            <button className="link_button" onClick={() => setToggleModal(false)}><ImCross color='white' /></button>
                                        </div>

                                        <div className='w3-row w3-section w3-padding'>
                                            <div className='w3-row'>
                                                Virtual Tour Title
                                                <input value={videoData.title} onChange={(e) => { handleInputChange(e) }} className="gret-dark-input w3-col" type="text" placeholder="Add Title for Virtual Tour" name="title" />
                                            </div>
                                            {/* <div className='w3-row w3-padding-small'>
                                                <textarea value={videoData.seo} onChange={(e) => { handleInputChange(e) }} className="gret-dark-input w3-col" type="text" placeholder="Add Seo Description for VR Video" name="seo" />
                                            </div> */}

                                        </div>
                                        <div className='w3-center'>{loadingMessage}</div>
                                        {!loading ?
                                            <div className='w3-row' style={{ display: 'flex', justifyContent: 'center', marginTop: '15px' }}>
                                                <input type="file" onChange={uploadVideo} className="custom-file-input" />
                                            </div>
                                            :
                                            <div className='w3-row' style={{ display: 'flex', justifyContent: 'center', marginTop: '15px' }}>
                                                <ThreeDotsLoader color="white" />
                                            </div>
                                        }
                                    </>

                                </Modal>
                            </>
                                :
                                <div className='center-item'>
                                    <button className="gret-blue-button w3-col" onClick={() => setToggleModal(true)}>Upload</button>
                                    <Link to="/profile/history/vr-videos" className="w3-section gret-black-button">My Virtual Tours</Link>
                                </div>
                            )
                            :
                            <button className='gret-blue-button w3-col l4 s8 m8' onClick={() => setLoginPopup(true)} >Login to upload model</button>
                        }
                    </div>
                </div>
                <div className='w3-quarter w3-padding'>
                    <div className='dark-card'>
                        <h1 style={{ borderRadius: '4px 4px 0px 0px' }} className='border-down-1 w3-xlarge no-margin w3-padding-small dark-bg-1 w3-center'>Create Websites</h1>
                        <div className='center-item w3-padding'>
                            <video autoPlay muted={true} webkit-playsinline="true" crossOrigin="anonymous" className='w3-col upload-container w3-section '>
                                <source src={'https://storage.googleapis.com/grubox-store/gret/avatar/Website%20Dribble/website_dribble_portfolio.webm'} type='video/mp4' />
                            </video>
                            <p className='w3-center'>Dive into our platform to create unique websites. With BuildVr's intuitive tools and templates, designers and digital creators can easily add images, videos, and immersive VR elements.</p>
                            <div className='w3-row w3-section w3-padding'>
                                <Link to='/create-vrwebsite' className=" center-item gret-black-button">Create Website</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='w3-row'>
                <div className='space-item-responsive w3-padding'>
                    <div className='dark-card w3-third w3-padding'>
                        <div className='left-item' style={{ gap: 10 }}>
                            <h1>1.</h1>
                            <div className='w3-container' style={{ borderLeft: "1px solid var(--new-ui-border2)" }}>
                                <h3>Upload Your 3D Model</h3>
                                <h4>Select the 3D model or environment you'd like to transform into an interactive virtual tour. We support .glb format only.</h4>
                            </div>
                        </div>
                    </div>
                    <div className='dark-card w3-third w3-padding'>
                        <div className='left-item' style={{ gap: 10 }}>
                            <h1>2.</h1>
                            <div className='w3-container' style={{ borderLeft: "1px solid var(--new-ui-border2)" }}>
                                <h3>Swift Transformation</h3>
                                <h4>Easily convert your 3D model into a virtual tour with seamless navigation. Optimized for desktop, mobile, and VR platforms for an immersive experience.</h4>
                            </div>
                        </div>
                    </div>
                    <div className='dark-card w3-third w3-padding'>
                        <div className='left-item' style={{ gap: 10 }}>
                            <h1>3.</h1>
                            <div className='w3-container' style={{ borderLeft: "1px solid var(--new-ui-border2)" }}>
                                <h3>Share Your Tour </h3>
                                <h4>Receive a shareable link for your virtual tour within seconds. Easily distribute it across platforms and let others explore your creation effortlessly.</h4>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='dark-card w3-row w3-padding' style={{ marginBlock: '4px', marginInline: '15px' }}>
                    <div className='left-item' style={{ gap: 10 }}>
                        <h3>Disclaimer </h3>
                        <div className='w3-container' style={{ borderLeft: "1px solid var(--new-ui-border2)" }}>
                            <h4>Videos that do not adhere to the&nbsp;
                                <a href='https://www.gretxp.com/terms'>terms and conditions</a>&nbsp;
                                of GretXP will be blocked and removed from the website. Any adult or inappropriate content will be automatically deleted.</h4>
                        </div>
                    </div>
                </div> */}

            </div>
            <Suspense fallback={<>...</>}>
                {statusCodeHandling &&
                    <StatusCodeHandling visible={statusCodeHandling} closeHandle={() => setStatusCodeHandling(false)} status_code={statusCode} />
                }
            </Suspense>
            {loginPopup &&
                <LoginModal visible={loginPopup} closeHandle={() => setLoginPopup(false)} />
            }
            {/* <ErrorModal visible={errorModal} closeHandle={() => setErrorModal(false)} errorMessage={loadingMessage} /> */}

        </div>
    )
}

export default VRTHomePage
