export const LandingImage = 'https://storage.googleapis.com/grubox-store/gret/gret_icons/1.png'
export const faces = 'https://storage.googleapis.com/grubox-store/gret/gret_icons/faces.png'
export const user_icon = "https://storage.googleapis.com/grubox-store/gret/img/profile.png";
export const new_reg_icon = "https://storage.googleapis.com/grubox-store/gret/img/coupon.png";
export const quest_icon = "https://storage.googleapis.com/grubox-store/gret/img/revenue.png";
export const images_icon = "https://storage.googleapis.com/grubox-store/gret/img/Reload_icon.png";
export const social_share_icon = "https://storage.googleapis.com/grubox-store/gret/img/Share_icon.png";
export const coupon_icon = "https://storage.googleapis.com/grubox-store/gret/img/Coupon_icon.png";
export const quest_claimed_icon = "https://storage.googleapis.com/grubox-store/gret/img/Gret_icon.png";
export const my_account_icon = "https://storage.googleapis.com/grubox-store/gret/img/Profile_icon.png";
export const meter_icon = "https://storage.googleapis.com/grubox-store/gret/img/Meter_icon.png";
export const logout_icon = "https://storage.googleapis.com/grubox-store/gret/avatar/Logout/Logout.svg";
export const intro_image_1 = "https://storage.googleapis.com/grubox-store/gret/img/intro1.png";
export const intro_image_2 = "https://storage.googleapis.com/grubox-store/gret/img/intro2.png";
export const intro_image_3 = "https://storage.googleapis.com/grubox-store/gret/img/intro3.png";
export const intro_image_4 = "https://storage.googleapis.com/grubox-store/gret/img/intro4.jpg";
export const intro_image_5 = ("https://storage.googleapis.com/grubox-store/gret/img/intro5.mp4");
export const activate_image = "https://media-private.canva.com/ze4M4/MAEJQKze4M4/1/s.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAJWF6QO3UH4PAAJ6Q%2F20220424%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20220424T125142Z&X-Amz-Expires=90374&X-Amz-Signature=d741d8ecb98ec62e410caf979141725af767802fc9459a7b95ff71ae90edd52f&X-Amz-SignedHeaders=host&response-expires=Mon%2C%2025%20Apr%202022%2013%3A57%3A56%20GMT";
export const create_video = "https://storage.googleapis.com/grubox-store/gret/assets/video.mp4"
export const Explainer3D = "https://storage.googleapis.com/grubox-store/gret/local/avatar/doggy/3D_Explainer.webp"
export const Product3D = "https://storage.googleapis.com/grubox-store/gret/local/avatar/doggy/3D_Product.webp"
export const Gamification = "https://storage.googleapis.com/grubox-store/gret/local/avatar/doggy/Gamification.webp"
export const VirtualEducation = "https://storage.googleapis.com/grubox-store/gret/local/avatar/doggy/Virtual_Education.webp"
export const VirtualMeeting = "https://storage.googleapis.com/grubox-store/gret/local/avatar/doggy/Virtual.webp"
export const VRTours = "https://storage.googleapis.com/grubox-store/gret/local/avatar/doggy/VR_Tours.webp"
export const greticon = "https://storage.googleapis.com/grubox-store/gret/local/avatar/doggy/Gretxp_Logo_2.webp"
export const coins = "https://storage.googleapis.com/grubox-store/gret/local/avatar/doggy/Coins.webp"
export const scenesImage = "https://storage.googleapis.com/grubox-store/gret/local/avatar/doggy/48.webp"
export const viewEye = "https://storage.googleapis.com/grubox-store/gret/local/avatar/doggy/49.webp"
export const storiesImage1 = "https://storage.googleapis.com/grubox-store/gret/local/avatar/doggy/preview_11.webp"
export const storiesImage2 = "https://storage.googleapis.com/grubox-store/gret/local/avatar/doggy/preview_7.webp"
export const storiesImage3 = "https://storage.googleapis.com/grubox-store/gret/local/avatar/doggy/preview_12.webp"
export const banner = "https://storage.googleapis.com/grubox-store/gret/local/avatar/doggy/Gretxp_Website_Banners_3.webp"
export const media2 = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/2.webp"
export const media3D_Websites = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/3D_Websites.webp"
export const media4 = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/4.webp"
export const media5 = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/5.webp"
export const media8 = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/8.webp"
export const media9 = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/9.webp"
export const media14_ = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/14_.webp"
export const media15 = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/15.webp"
export const media15_ = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/15_.webp"
export const media16 = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/16.webp"
export const media16_ = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/16_.webp"
export const media17 = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/17.webp"
export const media17_ = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/17_.webp"
export const media18 = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/18.webp"
export const media19 = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/19.webp"
export const media20 = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/20.webp"
export const media25 = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/25.webp"
export const media26 = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/26.webp"
export const media27 = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/27.webp"
export const media28 = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/28.webp"
export const media47 = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/47.webp"
export const media48 = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/48.webp"
export const media49 = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/49.webp"
export const media123FormBuilderSquare = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/123FormBuilderSquare.webp"
export const add = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/add.webp"
export const adsense = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/adsense.webp"
export const Avatar = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/Avatar.webp"
export const Banner1 = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/Banner1New.webp"
export const Banner2 = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/Banner2.webp"
export const calendy = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/calendy.webp"
export const hubspot = "https://storage.googleapis.com/grubox-store/gret/avatar/Icons (6)/Icons_6.svg"
export const Caption = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/Caption.webp"
export const cgtrader = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/cgtrader.webp"
export const Clear = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/Clear.webp"
export const Coins = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/Coins.webp"
export const dropbox = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/dropbox.webp"
export const EmailIcon = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/EmailIcon.webp"
export const FacebookIcon = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/FacebookIcon.webp"
export const Fashion = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/Fashion.webp"
export const Feed = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/Feed.webp"
export const free3d = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/free3d.webp"
export const Gmail = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/Gmail.webp"
export const GoogleDrive = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/GoogleDrive.webp"
export const GoogleIcon = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/GoogleIcon.webp"
export const Gret3d_creator_Desktop_ver_1 = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/Gret_-_3d_creator_Desktop_ver_1.webp"
export const Gret3d_creator_Desktop_ver_4 = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/Gret_-_3d_creator_Desktop_ver_4.webp"
export const Gret3d_creator_Desktop_ver_6 = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/Gret_-_3d_creator_Desktop_ver_6.webp"
export const Gret3d_creator_Desktop_ver_9 = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/Gret_-_3d_creator_Desktop_ver_9.webp"
export const Gret_BuildVr_Desktop_Mockup = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/Gret_-_BuilVR_Desktop_Mockup.webp"
export const Gret_Desktop_ver_2 = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/Gret_-_Desktop_ver_2.webp"
export const GretIcon = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/Gret-Icon.webp"
export const Instagram = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/Instagram.webp"
export const Integerations = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/Integrations.webp"
export const landscape = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/landscape.webp"
export const Leaderboard = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/Leaderboard.webp"
export const LowPoly = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/LowPoly.webp"
export const minus = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/minus.webp"
export const mixamo = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/mixamo.webp"
export const Music = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/Music.webp"
export const MyVR = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/MyVR.webp"
export const MyVRHeadset = "https://storage.googleapis.com/grubox-store/gret/avatar/BuilVR Create Page Desktop Mockup (1)/BuilVR_Create_Page_Desktop_Mockup_1.webp"
export const Office = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/Office.webp"
export const Profile = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/Profile.webp"
export const Props = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/Props.webp"
export const Realty = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/Realty.webp"
export const review1 = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/review1.webp"
export const review2 = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/review2.webp"
export const review3 = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/review3.webp"
export const review4 = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/review4.webp"
export const review5 = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/review5.webp"
export const rpm = "https://storage.googleapis.com/grubox-store/gret/avatar/Icons/Icons.svg"
export const RPMCard = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/RPMCard.webp"
export const sketchfab = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/sketchfab.webp"
export const Stage = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/Stage.webp"
export const twitter = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/twitter.webp"
export const Upload = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/Upload.webp"
export const userImage = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/userImage.webp"
export const Video = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/Video.webp"
export const WhatsApp = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/WhatsApp.webp"
export const youtube = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/youtube.webp"
export const Zoom = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/Zoom.webp"
export const ZoomCard = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/ZoomCard.webp"
export const StageImage = "https://storage.googleapis.com/grubox-store/gret/avatar/Events Stage/preview_7.webp"
export const MetaverseRoom = "https://storage.googleapis.com/grubox-store/gret/avatar/metaverse presentation room/Screenshot_2023-01-27_at_3.17.30_PM.webp"
export const Room = "https://storage.googleapis.com/grubox-store/gret/avatar/Room/preview_7.webp"
export const Textures = "https://storage.googleapis.com/grubox-store/gret/avatar/HandBrake-1.6.1-x86_64-Win_GUI/Textures.svg"
export const Images = "https://storage.googleapis.com/grubox-store/gret/avatar/HandBrake-1.6.1-x86_64-Win_GUI/Images.svg"
export const Size = "https://storage.googleapis.com/grubox-store/gret/avatar/HandBrake-1.6.1-x86_64-Win_GUI/Size.svg"
export const Light = "https://storage.googleapis.com/grubox-store/gret/avatar/HandBrake-1.6.1-x86_64-Win_GUI/Lights.svg"
export const Globe = "https://storage.googleapis.com/grubox-store/gret/avatar/HandBrake-1.6.1-x86_64-Win_GUI/Globe.svg"
export const Media7 = "https://storage.googleapis.com/grubox-store/gret/avatar/7/7.svg"
export const Media8 = "https://storage.googleapis.com/grubox-store/gret/avatar/8/8.svg"
export const Media9 = "https://storage.googleapis.com/grubox-store/gret/avatar/9/9.svg"
export const Media11 = "https://storage.googleapis.com/grubox-store/gret/avatar/11/11.svg"
export const Media6 = "https://storage.googleapis.com/grubox-store/gret/avatar/6/6.svg"
export const Media10 = "https://storage.googleapis.com/grubox-store/gret/avatar/10/10.svg"
export const Website3D = "https://storage.googleapis.com/grubox-store/gret/avatar/54/54.svg"
export const Interior3D = "https://storage.googleapis.com/grubox-store/gret/avatar/53/53.svg"
export const VRVideo = "https://storage.googleapis.com/grubox-store/gret/avatar/Icons%20(5)/Icons_5.png"
export const Metaverse3D = "https://storage.googleapis.com/grubox-store/gret/avatar/55/55.svg"
export const Visualisation3D = "https://storage.googleapis.com/grubox-store/gret/avatar/52/52.svg"
export const VirtualShowroom3D = "https://storage.googleapis.com/grubox-store/gret/avatar/51/51.svg"
export const Banner3D = "https://storage.googleapis.com/grubox-store/gret/avatar/Icons (7)/Icons_7.svg"
export const Logout3D = "https://storage.googleapis.com/grubox-store/gret/avatar/Icons (8)/Icons_8.svg"
export const Media12 = "https://storage.googleapis.com/grubox-store/gret/avatar/12/12.svg"
export const upload = "https://storage.googleapis.com/grubox-store/gret/avatar/Upload/Upload.svg"
export const VRWebsite = "https://storage.googleapis.com/grubox-store/gret/avatar/VRWebsite/VRWebsite.svg"
export const Environment = "https://storage.googleapis.com/grubox-store/gret/avatar/Environment/Environment.svg"
export const PropsIcon = "https://storage.googleapis.com/grubox-store/gret/avatar/PropsIcon/PropsIcon.svg"
export const Lightbulb = "https://storage.googleapis.com/grubox-store/gret/avatar/Lightbulb-blue/Lightbulb-blue.svg"
export const Media = "https://storage.googleapis.com/grubox-store/gret/avatar/Media/Media.svg"
export const Custom = "https://storage.googleapis.com/grubox-store/gret/avatar/Custom/Custom.svg"
export const MyVRIcon = "https://storage.googleapis.com/grubox-store/gret/avatar/MyVRIcon/MyVRIcon.svg"
export const Twitter = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/image-1.png"
export const Facebook = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/image-2.png"
export const InstagramIcon = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/image-4.png"
export const ProifileIcon = "https://storage.googleapis.com/grubox-store/gret/avatar/Profile/Profile.svg"
export const FeedIcon = "https://storage.googleapis.com/grubox-store/gret/avatar/Feed/Feed.svg"
export const Help = "https://storage.googleapis.com/grubox-store/gret/avatar/Help/Help.svg"
export const Media24 = "https://storage.googleapis.com/grubox-store/gret/avatar/24/24.svg"
export const Media25 = "https://storage.googleapis.com/grubox-store/gret/avatar/25/25.svg"
export const Media26 = "https://storage.googleapis.com/grubox-store/gret/avatar/26/26.svg"
export const Media27 = "https://storage.googleapis.com/grubox-store/gret/avatar/27/27.svg"
export const Media28 = "https://storage.googleapis.com/grubox-store/gret/avatar/28/28.svg"
export const Media29 = "https://storage.googleapis.com/grubox-store/gret/avatar/Icons/Icons.svg"
export const GretIcon1 = "https://storage.googleapis.com/grubox-store/gret/avatar/GretLogo1/GretLogo1.png"
export const GretIcon2 = "https://storage.googleapis.com/grubox-store/gret/avatar/GretLogo2/GretLogo2.png"
export const GretIcon3 = "https://storage.googleapis.com/grubox-store/gret/avatar/Icons (5)/Icons_5.svg"
export const GretIcon4 = "https://storage.googleapis.com/grubox-store/gret/avatar/Marketing%20Posters%20Gretxp/GRETNEWLOGO.png"
export const BuildvrLogo = "https://storage.googleapis.com/grubox-store/gret/avatar/Buildvr Logo (2)/Buildvr_Logo_2.svg"
export const landingpage1 = "https://storage.googleapis.com/grubox-store/gret/avatar/Social Media Posts/Social_Media_Posts.svg"
export const landingpage2 = "https://storage.googleapis.com/grubox-store/gret/avatar/Social Media Posts (1)/Social_Media_Posts_1.svg"
export const landingpage3 = "https://storage.googleapis.com/grubox-store/gret/avatar/Social Media Posts (2)/Social_Media_Posts_2.svg"
export const landingpage4 = "https://storage.googleapis.com/grubox-store/gret/avatar/Social_Media_Posts/Social_Media_Posts.webm"
export const canvas = "https://storage.googleapis.com/grubox-store/gret/avatar/34/34.svg"
export const header = "https://storage.googleapis.com/grubox-store/gret/avatar/30/30.svg"
export const footer = "https://storage.googleapis.com/grubox-store/gret/avatar/31/31.svg"
export const myvrwebsite = "https://storage.googleapis.com/grubox-store/gret/avatar/32/32.svg"
export const vrwebsiteTemplates = "https://storage.googleapis.com/grubox-store/gret/avatar/Templated/Templated.svg"
export const vrwebsiteApps = "https://storage.googleapis.com/grubox-store/gret/avatar/Apps/Apps.svg"
export const vrperformance = "https://storage.googleapis.com/grubox-store/gret/avatar/VRPerfomance/VRPerfomance.svg"
export const sifi = "https://storage.googleapis.com/grubox-store/gret/avatar/Sifi/Sifi.svg"
export const PageNotFound = "https://storage.googleapis.com/grubox-store/gret/avatar/Icons (9)/Icons_9.svg"
export const Avatar3D = "https://storage.googleapis.com/grubox-store/gret/avatar/Icons (10)/Icons_10.svg"
export const SpaceLogo = "https://storage.googleapis.com/grubox-store/gret/avatar/SpaceLogo/SpaceLogo.svg"
export const ContactUs = "https://storage.googleapis.com/grubox-store/gret/avatar/Icons/Icons.svg"
export const Subscription = "https://storage.googleapis.com/grubox-store/gret/avatar/Subscription/Subscription.svg"
export const ScrollMouse = "	https://storage.googleapis.com/grubox-store/gret/avatar/Icons%20(6)/Icons_6.png"

export const LandingPageNewUIImage = "https://storage.googleapis.com/grubox-store/gret/avatar/HERO/HERO.webp"
export const WebsiteBuilderIcon = "https://storage.googleapis.com/grubox-store/gret/avatar/website-builder/website-builder.svg"
export const VRshowroomIcon = "https://storage.googleapis.com/grubox-store/gret/avatar/world (1)/world_1.svg"
export const SocialPostIcon = "https://storage.googleapis.com/grubox-store/gret/avatar/post/post.svg"
export const VisualisationIcon = "https://storage.googleapis.com/grubox-store/gret/avatar/virtual-reality-fitness/virtual-reality-fitness.svg"
export const VRTourIcon = "https://storage.googleapis.com/grubox-store/gret/avatar/360/360.svg"
export const VRVideoIcon = "https://storage.googleapis.com/grubox-store/gret/avatar/virtual-tour/virtual-tour.svg"
export const EducationIcon = "https://storage.googleapis.com/grubox-store/gret/avatar/homework/homework.svg"

export const Layout1 = "https://storage.googleapis.com/grubox-store/gret/avatar/Group__237221/Group_237221.png"
export const Layout2 = "https://storage.googleapis.com/grubox-store/gret/avatar/Group 237223/Group_237223.png"
export const Layout3 = "https://storage.googleapis.com/grubox-store/gret/avatar/Group 237224/Group_237224.png"
export const Layout4_1 = "https://storage.googleapis.com/grubox-store/gret/avatar/Group 237225/Group_237225.png"
export const Layout4_2 = "https://storage.googleapis.com/grubox-store/gret/avatar/Group 237226/Group_237226.png"
export const Layout5_1 = "https://storage.googleapis.com/grubox-store/gret/avatar/Group 237226/Group_237226.png"
export const Layout5_2 = "https://storage.googleapis.com/grubox-store/gret/avatar/Group 237225/Group_237225.png"